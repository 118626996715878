@import "variables";

.tiles-4 {
  .tiles {
    display: grid;
    grid-template-columns: 30% 30% 40%;
    grid-template-rows: repeat(2, 1fr);
    min-height: 100vh;
    color: white;

    gap: $gap;
  }

  .tile {
    background-size: cover;
    background-position: center;
    background-color: rgba(255, 255, 255, 0.5);
    background-blend-mode: screen;
    min-height: 4em;
    padding: $gap;

    .tile-body {
      max-width: 20em;
      background: $alternative-color-1;
      padding: $gap;
    }

    &-heading {
      grid-column: span 3;
      background: $alternative-color-2;
      margin: 0;
    }
    &-1 {
      grid-column: span 2;
      grid-row: 2;
    }
    &-2 {
      grid-column: 3;
      grid-row: 2 / span 2;
    }
    &-3 {
      grid-column: 1;
      grid-row: 3;
    }
    &-4 {
      grid-column: 2;
      grid-row: 3;
    }
  }
}

@include breakpoint(medium down) {
  .tiles {
    display: block;
  }

  .tile {
    min-height: 50vh;
  }
}

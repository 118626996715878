@charset "UTF-8";
/* 
MERRIWEATHER SANS
main font | menu, headers, body

@font-face {
    font-family: 'Merriweather Sans VF';
    src: url('fonts/MerriweatherSans-VariableFont_wght.woff2') format('woff2-variations'),
         url('fonts/MerriweatherSans-VariableFont_wght.woff2') format('woff2');
    font-style: normal;
    font-display: swap;
    font-weight: 300 800;
}

@font-face {
    font-family: 'Merriweather Sans VF';
    src: url('fonts/MerriweatherSans-Italic-VariableFont_wght.woff2') format('woff2-variations'),
         url('fonts/MerriweatherSans-Italic-VariableFont_wght.woff2') format('woff2');
    font-style: italic;
    font-display: swap;
    font-weight: 300 800;
}
*/
@font-face {
    font-family: 'Merriweather Sans';
    src: url('fonts/MerriweatherSans-Light.eot');
    src: url('fonts/MerriweatherSans-Light.eot?#iefix') format('embedded-opentype'),
        url('fonts/MerriweatherSans-Light.woff2') format('woff2'),
        url('fonts/MerriweatherSans-Light.woff') format('woff'),
        url('fonts/MerriweatherSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Merriweather Sans';
    src: url('fonts/MerriweatherSans-LightItalic.eot');
    src: url('fonts/MerriweatherSans-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/MerriweatherSans-LightItalic.woff2') format('woff2'),
        url('fonts/MerriweatherSans-LightItalic.woff') format('woff'),
        url('fonts/MerriweatherSans-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Merriweather Sans';
    src: url('fonts/MerriweatherSans-Regular.eot');
    src: url('fonts/MerriweatherSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('fonts/MerriweatherSans-Regular.woff2') format('woff2'),
        url('fonts/MerriweatherSans-Regular.woff') format('woff'),
        url('fonts/MerriweatherSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Merriweather Sans';
    src: url('fonts/MerriweatherSans-Italic.eot');
    src: url('fonts/MerriweatherSans-Italic.eot?#iefix') format('embedded-opentype'),
        url('fonts/MerriweatherSans-Italic.woff2') format('woff2'),
        url('fonts/MerriweatherSans-Italic.woff') format('woff'),
        url('fonts/MerriweatherSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Merriweather Sans';
    src: url('fonts/MerriweatherSans-Medium.eot');
    src: url('fonts/MerriweatherSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('fonts/MerriweatherSans-Medium.woff2') format('woff2'),
        url('fonts/MerriweatherSans-Medium.woff') format('woff'),
        url('fonts/MerriweatherSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Merriweather Sans';
    src: url('fonts/MerriweatherSans-MediumItalic.eot');
    src: url('fonts/MerriweatherSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/MerriweatherSans-MediumItalic.woff2') format('woff2'),
        url('fonts/MerriweatherSans-MediumItalic.woff') format('woff'),
        url('fonts/MerriweatherSans-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Merriweather Sans';
    src: url('fonts/MerriweatherSans-SemiBold.eot');
    src: url('fonts/MerriweatherSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('fonts/MerriweatherSans-SemiBold.woff2') format('woff2'),
        url('fonts/MerriweatherSans-SemiBold.woff') format('woff'),
        url('fonts/MerriweatherSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Merriweather Sans';
    src: url('fonts/MerriweatherSans-SemiBoldItalic.eot');
    src: url('fonts/MerriweatherSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/MerriweatherSans-SemiBoldItalic.woff2') format('woff2'),
        url('fonts/MerriweatherSans-SemiBoldItalic.woff') format('woff'),
        url('fonts/MerriweatherSans-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Merriweather Sans';
    src: url('fonts/MerriweatherSans-Bold.eot');
    src: url('fonts/MerriweatherSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('fonts/MerriweatherSans-Bold.woff2') format('woff2'),
        url('fonts/MerriweatherSans-Bold.woff') format('woff'),
        url('fonts/MerriweatherSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Merriweather Sans';
    src: url('fonts/MerriweatherSans-BoldItalic.eot');
    src: url('fonts/MerriweatherSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/MerriweatherSans-BoldItalic.woff2') format('woff2'),
        url('fonts/MerriweatherSans-BoldItalic.woff') format('woff'),
        url('fonts/MerriweatherSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}
// This is a file that can be overridden by the application in order to override styles
// Notice that this file is included at the very end of the stylesheets packs to have
// more priority
//
// To override CSS variables or Foundation settings use _decidim-settings.scss
//
// By default this is empty.

@import "stylesheets/decidim/app/app-fontface";
@import "stylesheets/decidim/app/app-main";
@import "stylesheets/decidim/app/app-footer";

.myrevolution_Grafik_Desktop{
    display:none;
}

@include breakpoint(large){
    .myrevolution_Grafik_Mobile{
        display:none;
    }
    .myrevolution_Grafik_Desktop{
        display: block;
    }
}
// This is a file that can be overridden by the application in order to override
// some of the Foundation/Decidim SCSS settings and variables after the default
// settings have been loaded.
//
// To override styles use decidim_application.scss
//
// By default this is empty.

/*
// 2. Breakpoints
// --------------

$breakpoints: (
  small: 0,
  smallmedium: 450px,
  medium: 640px,
  mediumlarge: 800px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
);
$print-breakpoint: large;
$breakpoint-classes: (small smallmedium medium mediumlarge large);
*/

// add a breaktpoint

$breakpoints: (
  small: 0,
  smallsmallmedium: 400px,
  smallmedium: 450px,
  medium: 640px,
  mediumlarge: 800px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
  xxxlarge: 1600px,
);

// fonts
$body-font-family: 'Merriweather Sans', Helvetica, Roboto, Arial, sans-serif;
//$body-font-family-variable: "Merriweather Sans VF", Helvetica, Roboto, Arial, sans-serif;
$header-font-family: $body-font-family;

// colors

$yellow-logo: #fec23f;
$green-logo: #589ba4;

$green-wcag: #407077;
$yellow-wcag: #9e6c01;

$yellow-background: #fffaf0; // #FFF7E5; // #FFEEC9;
$green-background: #ecf4f3;

$light-gray: #fafafa;
$medium-gray: #c1c4c4;
$dark-medium-gray: #828989;
$dark-gray: #444e4f;
$black: #061314;
$white: #ffffff;
$light-gray-dark: darken($light-gray, 2.5) !default;
$body-background: $white;
$body-font-color: $black;
$navbar-color: $green-wcag;

$titlebar-background: transparent;
$titlebar-padding: 0rem 0rem 0.5rem 0rem;

$button-hollow-border-width: 3px;

$button-brightness-hover: 100%;
$button-brightness-active: 100%;
$button-brightness-focus: 100%;

@mixin link_to_button {
  // create button
  display: block;
  padding: 0.8rem 1rem !important;
  background: $white;
  border: 3px solid $yellow-logo;
  border-radius: 3.75rem;
  color: $yellow-wcag;
  font-size: 0.9rem;
  line-height: 1;
  font-weight: 500;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  &:hover,
  &:focus,
  &:active {
    background-color: $yellow-wcag;
    color: $white;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
  }
  &:focus {
    outline: 1px dotted $yellow-logo;
  }
}

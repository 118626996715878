//Vars
$footer-bg: $light-gray;

//Common

.main-footer,
.mini-footer{
  color: $black;
  .link-separator{
    margin: 0 .5rem;
  }
  a{
    
    &:focus{
      outline-color: var(--highlight-alternative);
    }
  }
}

//Main footer
.main-footer{
  background-color: $footer-bg;
  position: relative;
  padding: 1rem 0;
  min-height: 76px;
}

.main-footer__badge{
  display: block;
  width: 140px;

  @include breakpoint(large){
    position: absolute;
  }
}

.main__footer__nav{
  text-align: center;

  @include breakpoint(medium){
    text-align: left;
  }

  @include breakpoint(large){
    text-align: center;
  }
}

.footer-nav{
  list-style: none;
  padding-top: .5rem;
  margin: 0 0 0 -1rem;

  li{
    display: inline-block;
    padding: 0 1rem;
  }

  @include breakpoint(large){
    margin-left: 0;
  }
}

.main__footer__social{
  text-align: center;

  @include breakpoint(medium){
    text-align: right;
  }
}

.footer-social{
  list-style: none;
  padding-top: .5rem;
  margin: 0;

  li{
    display: inline-block;
    padding-left: 1rem;

    &:first-child{
      padding-left: 0;
    }
  }
}

.footer-social__icon .icon{
  width: 16px;
  height: 16px;
}

//Mini footer
.mini-footer{
  padding: 1rem 0;
  background-color: $footer-bg;
}

.cc-badge{
  margin-right: .5rem;
}

.decidim-logo{
  display: block;
  width: 140px;
  margin-right: auto;
  margin-left: auto;
}

$nav-border-width: 3px;

.process-nav {
  padding: 0.75rem $container-padding-y;
  ul {
    margin: 0;
    list-style: none;
  }

  .about-link {
    font-size: 80%;
  }

  @include breakpoint(medium) {
    padding: 0;

    ul {
      width: fit-content;
      margin: auto;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      white-space: nowrap;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
    }

    ul::-webkit-scrollbar {
      display: none;
    }

    ul::after {
      display: inline-block;
      flex-grow: 1;
      content: '';
    }

    li {
      display: inline-block;
      padding: 0.5rem 0.75rem 0.75rem + rem-calc($nav-border-width - 1px);
    }

    .about-link {
      float: right;
      margin-right: 0;
      margin-left: auto;
    }
  }
}

.process-nav__trigger {
  display: block;
  width: 100%;
  text-align: left;
  position: relative;
}

.process-nav__trigger__icon {
  position: absolute;
  right: 0.5rem;
  top: 0.55rem;
}

.process-nav__content {
  @include breakpoint(smallmedium down) {
    display: none;
  }

  &.is-active {
    display: block;
  }

  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.process-nav__content li {
  @include breakpoint(smallmedium down) {
    margin-top: 1rem;
  }

  &.is-active {
    @include breakpoint(smallmedium down) {
      display: none;
    }
  }
}

.process-nav__more {
  margin: 0;
  color: var(--primary);
  background-color: rgba(var(--primary-rgb), 0.1);
  font-weight: $global-weight-bold;
  font-size: rem-calc(14);

  i {
    @include square(5px);

    border-radius: 100%;
    background-color: var(--primary);
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0.1rem;

    &:not(:last-child) {
      margin-right: 0.1rem;
    }
  }
}

.process-nav__hidden-content {
  background-color: $white;
  box-shadow: 0 0 4px 0 rgba($black, 0.08);
  padding: 0;

  ul {
    padding: $global-padding * 0.5 0;
  }

  li {
    width: 100%;
    padding: $global-padding * 0.5 $global-padding;
  }
}

.process-nav__hidden-content__more {
  @extend .process-nav__more;

  background-color: transparent;
  text-align: right;
  border-bottom: $border;
  padding: $global-padding * 0.5 $global-padding;
}

.process-nav__link {
  text-transform: uppercase;
  color: var(--primary);
  font-weight: $global-weight-bold;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: none;
  }

  svg {
    margin-right: 0.4em;
    width: 25px;
    height: 25px;
    fill: $muted;

    .stroked-shape {
      fill: none;
      fill-opacity: 1;
      stroke-width: 2.01957917;
      stroke-miterlimit: 4;
      stroke-dasharray: none;
      stroke-dashoffset: 0;
      stroke: $muted;
    }
  }
  @include breakpoint(medium) {
    padding: 0.8rem 1rem;
    border-radius: 3.75rem;
    border: 3px solid;
    font-size: 0.9rem;
    font-weight: 500;
    text-transform: uppercase;
    @include button-hollow-variant($yellow-wcag, $yellow-logo);

    &.active {
      background: $yellow-wcag;
      color: $white;
      text-decoration: none;
    }
  }
}

@include breakpoint(medium) {
  .process-nav__link.is-active {
    color: var(--primary);

    svg {
      fill: var(--primary);

      .stroked-shape {
        stroke: var(--primary);
      }
    }
  }
}

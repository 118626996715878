@import 'stylesheets/decidim/enhanced_textwork/paragraphs/preview';

// variables
#paragraphs {
  background-color: $white;
  padding: 1.125rem;
}

.paragraphs-overview {
  overflow: unset;
}

.toggle--hidden {
  display: none;
}

.mobile {
  display: none;
}

.paragraph {
  font-weight: 300;
  padding: 1rem;
  margin-bottom: 0.5rem;
  border: 1px dotted transparent;
  border-bottom-color: $dark-medium-gray;
  p,
  li {
    font-weight: 300;
    font-size: 0.9rem;
  }
  strong {
    font-weight: 600;
  }
  &__back-button {
    display: none;
  }
  &__layout {
    display: flex;
  }
  &__main {
    width: 66.6667%;
  }
  &__sidebar {
    width: 33.333%;
    position: sticky;
    top: 0;
    height: 100vh;
    padding: 0 0 3rem 10px;
    overflow: scroll;
    scroll-behavior: smooth;
  }
  a {
    color: inherit;
    &:hover {
      text-decoration: none;
    }
  }
  &__actions {
    .right-column {
      display: flex;
      justify-content: space-between;
    }
    a {
      font-weight: 500;
      font-size: 0.8rem;
      color: $dark-gray;
      &:hover {
        text-decoration: underline;
      }
    }
    button {
      color: $dark-gray;
      font-weight: 500;
      font-size: 0.8rem;
      line-height: 1.5;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .paragraph_opener {
    display: none;
  }

  &:hover {
    .paragraph__actions {
      a {
        color: $green-wcag;
      }
      button {
        color: $green-wcag;
        cursor: pointer;
      }
    }
  }

  &__overview {
    h2 {
      font-size: 1.625rem;
      margin-bottom: 1rem;
      padding-left: 1.125rem;
    }
    h3 {
      font-size: 1rem;
    }
    .link {
      font-size: 0.9rem;
    }
    .amendments,
    .comments {
      padding: 1.125rem;
    }
    .amendments {
      margin-bottom: 1.125rem;
      h3 {
        margin: 0;
      }
      .card {
        margin-bottom: 0;
        margin-top: 0.5rem;
      }
    }
    .comments__header {
      h3 {
        float: left;
      }
      a {
        float: right;
        font-size: 0.9rem;
      }
    }
  }
}

.paragraph--active {
  border-style: solid;
  border-color: $green-logo;
  border-radius: 0.3rem;
  .paragraph__actions {
    a {
      color: $green-wcag;
    }
    button {
      color: $green-wcag;
    }
  }
}

.emendation {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .amendment__header {
    padding: 1rem 1rem 0.5rem 1rem;
  }

  .amendment__author {
    display: flex;
  }

  .amendment__date {
    font-style: italic;
    color: #9b9b9b;
    font-size: 0.7rem;
  }

  .amendment__body {
    padding: 0.5rem 1rem;
  }

  .amendment__actions {
    padding: 1rem 1rem 0.5rem 1rem;
    font-size: 0.9rem;
  }
}

.paragraph__sidebar {
  .icon.mobile {
    display: block;
    height: 1.375rem;
    width: 1.375rem;
  }
}

.paragraph__hover-section {
  .paragraph-actions {
    color: #9b9b9b;
  }

  &:hover {
    .paragraph {
      background-color: $green-background;
    }

    .paragraph-actions {
      color: $green-wcag;
    }
  }

  .hidden-section {
    display: none;
  }
}

.paragraphs {
  .comment {
    border: 1px solid $medium-gray;
  }
}

/* Mobile */
@media screen and (max-width: 1125px) {
  .paragraphs {
    .view_desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
    .icon.mobile {
      height: 1.75rem;
      width: 1.75rem;
    }
    .view-side {
      float: left !important;
    }
  }
  .paragraph__sidebar {
    .icon.mobile {
      display: block;
      height: 1.375rem;
      width: 1.375rem;
    }
  }
  .paragraph {
    &__layout {
      display: block;
    }
    &__sidebar {
      width: 100%;
      padding: 0;
      overflow: auto;
      height: auto;
      display: none;
    }
    .right-column {
      justify-content: space-around;
      align-items: flex-end;
    }
    .button_to button {
      padding-right: 0;
      padding-left: 0;
    }
    .paragraph_title_bar {
      display: flex;
      align-items: flex-start;
      gap: 0.5rem;
      padding: 0.5rem 0.5rem 0 0;
    }
    .paragraph_opener {
      cursor: pointer;
    }
    svg.paragraph_opener {
      flex-shrink: 0;
      margin-left: auto;
      transition: transform 0.5s ease;
    }
    svg.paragraph_opener.active {
      transform: rotate(180deg);
      transition: transform 0.5s ease;
      /* change with variable */
      color: var(--green-wcag);
    }
    &.paragraph--active {
      svg.paragraph_opener {
        display: none;
      }
    }

    &:not(.paragraph--active) .paragraph_content {
      height: 0;
      overflow: hidden;
      transition: height 0.5s ease;
    }
    .paragraph_content.open {
      height: auto;
      transition: height 0.5s ease;
    }
    .pb-s {
      padding-top: 1rem;
    }
  }
  .paragraph__main {
    width: 100%;
    padding: 0;
  }
  .paragraphs-overview {
    .paragraph {
      display: none;
      &__sidebar {
        display: block;
        overflow: auto;
        height: auto;
      }
      &__back-button {
        display: flex;
        align-items: center;
      }
      &--active {
        display: block;
      }
    }
    .paragraphs_title {
      display: flex;
      align-items: baseline;
      gap: 0.5rem;
    }
    .ml--15 {
      margin-left: -12px;
    }
  }
}

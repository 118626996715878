.process-header{
  background-image: $white;

  .heading2{
    font-weight: bold;
  }
}

.process-header__inner{
  position: relative;

  @include flex;
  @include flex-align($x: center);
}

.process-header__main{
  background-size: cover;
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  max-width: rem-calc(1920);
  margin: 0 auto;
  background-color: lighten($dark-gray, 30);

  &::after{
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, .6);
  }
}

.process-header__container{
  position: relative;
  z-index: 1;

  @include breakpoint(mediumlarge){
    display: flex;
    align-items: center;
  }
}

.process-header__info{
  padding: 1rem 1.5rem 1rem 1rem;

  @include breakpoint(medium){
    padding: 2rem 1.5rem 2rem 1rem;
  }

  @include breakpoint(mediumlarge){
    padding-left: 2rem;
  }
}

.process-header__hashtag{
  font-weight: 600;

  &::after{
    content: "·";
    margin-left: .5rem;
    margin-right: .5rem;
  }
}

.process-header__nav{
  margin-top: auto;
  z-index: 1;
}

.mini-footer .row{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    line-height: 1.5rem;
    padding: 2rem 0;
    flex-direction: column;
    a.small_logo{
        margin:0.5rem 0;
    }
    .external-link-container{
        padding-left: 0.3rem;
        padding-inline-start: 0.3rem;
        padding-right: 0.3rem;
        padding-inline-end: 0.3rem;
    }
    &.footer_mitgestalten{
        padding:0 0 1rem 0;
        font-size: 0.8rem;
        font-weight: 600;
        text-transform: uppercase;
        .mitgestalten_logo{
            max-width:200px;
        }
    }
    @include breakpoint(smallmedium){
        flex-direction: row;
        a.small_logo{
            margin:0;
        }
    }
    &:first-child {
        padding:2rem 1rem;
        gap:2.5rem;
        img{
            max-height: 80px;
            width: auto;
        }
    }
}

.mini-footer a, .footer-social .icon{
    &:hover{
        transition: 0.2s ease-in;
        text-decoration: none;
    }
}

a.small_logo{
    height:1.5rem;
    .svg-logo{
        height:1.5rem;
        width:auto;
        margin:0 0.5rem;
        &:hover{
            transition: 0.2s ease-in;
        }
    }
    
} 
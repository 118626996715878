// Home

.hero{
  background-size: cover;
  position: relative;
  z-index: 0;

  &::after{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($white, .7);
  }

  a{
    &:focus{
      outline-color: var(--highlight-alternative);
    }
  }
}

.hero__container{
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 3rem 0;

  @include breakpoint(medium){
    padding: 4rem 0;
  }

  @include breakpoint(mediumlarge){
    padding: 5rem 0;
  }

  @include breakpoint(large){
    padding: 6rem 0;
  }
}

.hero__boxes{
  text-align: center;
  border-radius: 4px;
  background-color: rgba($white, .6);
  color: $white;
  margin-top: 1rem;
  padding: $global-padding;

  @include breakpoint(medium){
    text-align: left;
    padding: 2rem 1.5rem;
    margin-top: 2rem;
  }

  @include breakpoint(mediumlarge){
    margin-top: 3rem;
  }

  @include breakpoint(large){
    margin-top: 4rem;
  }

  p{
    font-size: rem-calc(17);
  }

  .button{
    display: block;
    margin: 0 auto;
  }

  .box__search{
    position: relative;
    margin: 0 auto;

    $color: rgba($body-font-color, .3);

    input,
    input:focus{
      color: $body-font-color;
      margin: 0;
      box-shadow: none;
      background: $white;

      // NOTE: Input height same as button height
      line-height: 1;
      padding: $button-padding;
      padding-left: $input-padding * 4;
      height: auto;
      border: 1px solid transparent;

      &::-webkit-input-placeholder{
        /* Chrome/Opera/Safari */
        color: $color;
      }

      &::-moz-placeholder{
        /* Firefox 19+ */
        color: $color;
      }

      &:-ms-input-placeholder{
        /* IE 10+ */
        color: $color;
      }

      &:-moz-placeholder{
        /* Firefox 18- */
        color: $color;
      }
    }

    svg{
      color: $color;
      position: absolute;
      top: 50%;
      left: $input-padding;
      transform: translateY(-50%);
      opacity: .5;
    }
  }
}

.hero-heading{
  margin-bottom: 1.5rem;
  font-size: 2rem;
  line-height: 1;
  text-shadow: 0 0 5px rgba(black, .8);

  br{
    display: none;
  }

  @include breakpoint(medium){
    font-size: 2.5rem;

    br{
      display: block;
    }
  }

  @include breakpoint(mediumlarge){
    font-size: 3.5rem;
  }

  @include breakpoint(large){
    font-size: 4.5rem;
  }
}

.hero-cta{
  margin-top: 1rem;
  padding: 1.2rem 1rem;

  @include breakpoint(medium){
    margin-top: 2rem;
  }

  @include breakpoint(mediumlarge){
    margin-top: 3rem;
  }

  @include breakpoint(large){
    margin-top: 4rem;
  }
}

.wrapper-home{
  padding: 4rem 1rem;

  @include breakpoint(medium){
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  @include breakpoint(large){
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

.wrapper-home--mini{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.home-section:nth-of-type(2n + 1){
  background-color: $yellow-background;
  .button{
    @include button-hollow-variant($yellow-wcag,$yellow-logo);
  }
}

.home-section:nth-of-type(2n){
  background-color: $green-background;
  .button{
    @include button-hollow-variant(var(--primary),$green-logo);
  }
}

.subhero{
  padding: 4rem 0;
  text-align: center;

  ul,
  ol{
    list-style-position: inside;
  }

  .heading3{
    @include breakpoint(medium down){
      font-size: 1.3em;
    }
  }

  .heading2{
    @include breakpoint(medium down){
      font-size: 1.5em;
    }
  }

  .heading4{
    @include breakpoint(medium down){
      font-size: 1.2em;
    }
  }
}

.subhero-cta{
  margin-top: 2rem;
  color: var(--primary);
  display: inline-block;
  position: relative;

  &:hover,
  &:focus{
    text-decoration: underline;
    color: var(--primary);
  }

  .icon{
    position: absolute;
    top: .33em;
    right: -1em;
    transition: .3s right;
  }

  &:hover .icon{
    right: -1.2em;
  }
}

.subhero-cta--secondary{
  margin-top: 0;
}

.home-section__cta{
  margin-top: 2rem;
}

.home-bullets{
  margin-top: 2rem;
  margin-bottom: 2rem;

  @include breakpoint(mediumlarge){
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}

.home-bullet{
  overflow: hidden;
}

.home-bullet__icon{
  float: left;
  //width: 15%;
  text-align: right;

  svg{
    width: 30px;
    height: 30px;
    fill: var(--primary);
  }
}

.home-bullet__desc{
  float: right;
  width: 85%;
  width: calc(100% - 30px);
  padding-left: 1rem;
}

.home-map{
  .wrapper-home{
    padding-bottom: 0;
  }

  #google-map{
    margin-bottom: 0;
  }
}

/* Home Statistics */

.home-stats{
  overflow: hidden;
  margin: 2rem 0;

  @include breakpoint(mediumlarge){
    margin: 5rem 0;
  }

  .statistics-cell{
    margin: 0;
  }
}

.home-stats__highlight,
.home-stats__lowlight{
  .statistic__data::after{
    border: none;
  }
}

.home-stats__highlight{
  @include breakpoint(medium){
    display: flex;
  }

  .statistic__data{
    margin: 0;
    padding: 1rem 2rem;

    @include breakpoint(medium){
      width: 50%;
      border-bottom: $border;
    }

    &:nth-child(odd){
      border-right: $border;
    }

    &:last-child{
      border-right: none;
    }
  }

  .statistic__number{
    font-size: 4rem;
    line-height: 1;
  }
}

.home-stats__lowlight{
  .statistic__data{
    padding: .25rem;
    width: calc(33% - 1rem);

    @include breakpoint(mediumlarge){
      text-align: left;

      &:first-child,
      &:last-child{
        padding-left: 0;
      }
    }
  }

  .statistic__number{
    font-size: 1.2rem;
    font-weight: 800;
  }

  .statistic__title{
    display: inline;
  }
}

@import "variables";

@mixin cover-background-image($color) {
  background-size: cover;
  background-position: center;
  background-blend-mode: luminosity;
  background-color: $color;
  min-height: 100vh;
}

.cover-full,
.cover-half {
  .cover-body {
    font-size: 1.25em;
  }
}

.cover-full {
  color: var(--color_text);

  @include cover-background-image(var(--color_background_image));

  .cover-text {
    background-color: rgba(var(--color_background_text-rgb), 0.7);
    padding: $gap * 2;
  }
}

.cover-half {
  display: grid;
  grid-template-columns: 40em 1fr;

  .cover-image {
    grid-column: 2;

    @include cover-background-image(var(--color_background_image));
  }

  .cover-text {
    padding: $gap * 3;
  }

  @include breakpoint(medium down) {
    display: block;

    .cover-image {
      min-height: 50vh;
    }

    .cover-text {
      padding: $gap;
    }
  }
}

.navbar.transparent {
  background-color: rgba(var(--color_navbar-rgb), 0.3);
  position: relative;
  margin-bottom: -50px;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.header {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 25vh;
  @include breakpoint(medium) {
    height: 45vh;
  }
  @include breakpoint(large) {
    height: 60vh;
  }
  @include breakpoint(xxxlarge) {
    height: 45vh;
  }
}
.homepage {
  .header {
    height: 25vh;
    @include breakpoint(medium) {
      height: 55vh;
    }
    @include breakpoint(large) {
      height: 75vh;
    }
    @include breakpoint(xlarge) {
      height: 90vh;
    }
  }
}
.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: none;
}

.shoutout_container {
  background: #fff;
  padding: 0;
  z-index: 100;
  position: relative;
  @include breakpoint(medium) {
    padding: 0 0 3rem 0;
  }
  @include breakpoint(large) {
    padding: 0 0 5rem 0;
  }
  @include breakpoint(xlarge) {
    padding: 0 0 4rem 0;
  }
  .revolution_shoutout {
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 4rem;
    display: flex;
    align-items: center;
    transform: rotate(-17.772deg);
    h2 {
      font-family: Impact;
      font-size: 2rem;
      line-height: 4rem;
      font-style: normal;
      font-weight: 400;
      color: $white;
      margin-bottom: 0rem;
      @include breakpoint(smallsmallmedium) {
        font-size: 2.5rem;
        line-height: 5.5rem;
      }
      @include breakpoint(medium) {
        font-size: 4rem;
        line-height: 10rem;
      }
      @include breakpoint(large) {
        font-size: 6rem;
        line-height: 13rem;
      }
      @include breakpoint(xlarge) {
        font-size: 6.5rem;
        line-height: 16rem;
      }
      a {
        line-height: inherit;
        color: inherit;
        text-decoration: none;
        &:hover {
          color: inherit;
          text-decoration: none;
        }
      }
    }
    @include breakpoint(smallsmallmedium) {
      min-height: 5rem;
    }
    @include breakpoint(medium) {
      min-height: 9rem;
    }
    @include breakpoint(large) {
      min-height: 12rem;
    }
    @include breakpoint(xlarge) {
      min-height: 15rem;
    }
  }
}
#participatory-space-filters {
  display: none;
}
.social-share {
  fill: $green-wcag;
  &:hover {
    fill: $white;
  }
}

.callout.flash {
  z-index: 1000;
  box-shadow: 0 1px 25px $dark-gray;
}

/* hack for ther quill editor inserting <br> between <li> elements */
.ql-editor-display {
  ul > br,
  ol > br {
    display: none;
  }
}

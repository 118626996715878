/* Variables */

/* Typography - Foundation overrides */

button,
input{
  font-family: inherit;
}

a{
  color: $anchor-color;
  font-weight: $anchor-font-weight;

  &:hover{
    color: $anchor-color-hover;
    text-decoration: underline;
    text-decoration-skip-ink: auto;
  }

  &:focus{
    outline: $anchor-outline-focus;
    outline-offset: $anchor-outline-offset;
  }
}

button{
  &:focus{
    outline: $button-outline-focus;
    // outline-color: var(--highlight);
  }
}

@mixin heading($heading){
  line-height: 1.2;
  font-size:
    (map-get(
      map-get(map-get($header-styles, small), $heading),
      "font-size"
    ) * .0625) + em;

  @include breakpoint(medium){
    font-size:
      (map-get(
        map-get(map-get($header-styles, medium), $heading),
        "font-size"
      ) * .0625) + em;
  }
}

.heading1{
  font-weight: 600;

  @include heading(h1);

  line-height: 1.1;
}

.subheading1{
  text-align: center;
  font-size: 1.25rem;
  margin: 0 auto;
  max-width: 600px;
  padding-bottom: 2rem;
  margin-top: -3rem;
}

.heading2{
  @include heading(h2);
}

.heading3{
  @include heading(h3);
}

.heading4{
  @include heading(h4);
}

.heading5{
  @include heading(h5);
}

.heading6{
  text-transform: uppercase;
  letter-spacing: .03em;
  font-weight: 600;

  @include heading(h6);
}

.heading-small{
  font-size: 1rem;
  line-height: $header-lineheight;
}

hr{
  width: 50%;

  &.reset{
    width: 100%;
  }
}

/* New typographic styles */

.section-heading{
  position: relative;
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 1.125em;
  letter-spacing: .05em;
  text-transform: uppercase;

  &::before{
    content: "";
    display: inline-block;
    width: 4px;
    height: 1.35em;
    background-color: var(--primary);
    margin-right: .5rem;
    margin-bottom: -.5rem;
    vertical-align: top;
  }

  &.collapse{
    margin: 0;
  }

  span{
    font-weight: normal;
  }
}

.mini-title{
  color: $muted;
  font-size: .9rem;
  letter-spacing: .01em;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 0;

  &__strong{
    color: $body-font-color;
    font-size: 1.2rem;
    font-weight: 800;
  }

  &__strong--highlight{
    font-size: 1.4rem;
  }
}

.data-title{
  &__over{
    color: $muted;
    font-size: .9rem;
    letter-spacing: .01em;
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: .5rem;
  }

  &__main{
    font-size: 2rem;
    line-height: 1;
    letter-spacing: .01em;
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 0;
  }

  &__sub{
    color: $muted;
    font-size: .9rem;
    letter-spacing: .01em;
    text-transform: uppercase;
    line-height: 1;
  }
}

.page-title-wrapper{
  text-align: center;
}

.page-title{
  margin-bottom: 3rem;

  a:hover{
    text-decoration: underline;
  }
}

.text-highlight{
  margin-bottom: 0;
  color: var(--secondary);

  &.heading1{
    font-weight: 600;
  }

  > a{
    color: inherit;

    &:hover{
      color: var(--primary);
    }
  }
}

// text status-color
$map: map-merge(
  $foundation-palette,
  (
    muted: $muted,
  )
);

@each $key, $value in $map{
  .text-#{$key}{
    color: $value;
  }

  .bg-#{$key}{
    background-color: lighten($value, 30);
  }
}

.text-large{
  font-size: 130%;
}

.text-medium{
  font-size: 90%;
}

.text-small{
  font-size: 80%;
}

.text-sm{
  font-size: 80%;
}

.text-uppercase{
  text-transform: uppercase;
}

.text-lowercase{
  text-transform: lowercase;
}

.text-ellipsis{
  @include ellipsis();
}

.text-compact{
  line-height: 1;
}

.word-wrapper{
  display: inline-block;
}

/* HTML inline text elements. Modifiers color text */
a,
abbr,
acronym,
b,
bdo,
big,
br,
button,
cite,
code,
dfn,
em,
i,
img,
input,
kbd,
label,
map,
object,
q,
samp,
script,
select,
small,
span,
strong,
sub,
sup,
textarea,
time,
tt,
var{
  @include modifiers(
    color,
    (
      muted: $muted,
    )
  );
}

ul.no-bullet-indented,
ol.no-bullet-indented{
  list-style-type: none;
}

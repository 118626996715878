.highligted-content-banner{
  background-size: cover;
  position: relative;
  z-index: 0;
  min-height: 255px;
  /*
  &::after{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, .6);
  }
  */
  .highligted-content-banner__container{
    position: relative;
    z-index: 1;
    padding: 3rem 0;

    @include breakpoint(medium){
      padding: 4rem 0;
    }

    @include breakpoint(mediumlarge){
      padding: 5rem 0;
    }

    @include breakpoint(large){
      padding: 6rem 0;
    }
  }
}
